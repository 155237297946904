<template>
  <div class="meeting minSize"  id='screenshot' @mouseleave="menuBarLeaveHandler">
    <!-- 顶部操作栏 -->
    <div class="top" @mouseover="overMenuBarHandle" v-show="isShowMenuBar">
      <div class="left">
        <div class="title" v-if="![10008].includes($configs.businessType*1)">{{ $t('common.title') }}</div>
        <!-- 信息 -->
        <meetingTopLeftInfo :mopanInfo="mopanInfo" :mopanLink="mopanLink"></meetingTopLeftInfo>
        <!-- 安全 -->
        <meetingTopLeftSafety></meetingTopLeftSafety>
        <!-- 网络 -->
        <meetingTopLeftNetwork :networkInfo="networkInfo" @openNetwork="isShowNetwork = true"></meetingTopLeftNetwork>
        <!-- 录制浮层 -->
        <meetingTopLeftRecord v-if="isUserRecording"></meetingTopLeftRecord>
      </div>
      <div class="right"  data-html2canva00s-ignore>
        <meetingTopRightUploadLog pos="center"></meetingTopRightUploadLog>
        <!-- 帮助 -->
        <meetingTopRightHelp></meetingTopRightHelp>
        <!-- 客服 -->
        <meetingTopRightKeFu></meetingTopRightKeFu>
        <!-- 免费时长倒计时 -->
        <div v-if="maturityTimeStr" class="freeCoutTime">{{$t('meeting.haveTime')}}{{ maturityTimeStr }}</div>
        <!-- 时间 -->
        <meetingTopRightTime v-show='isShowTime'></meetingTopRightTime>
        <!-- 切换布局 -->
        <meetingTopRightChangeLayout></meetingTopRightChangeLayout>
        <!-- 全屏 -->
        <meetingTopRightScreenfull></meetingTopRightScreenfull>
      </div>
    </div>

    <!-- 视频主区域 -->
    <div @mousemove="menuBarMoveHandler" id="meet-main" class="main">
      <!-- 会场布局 -->
      <layoutGallery v-if="layoutType === LAYOUT_CONFIG.GALLERY9" />
      <layoutGalleryMCU v-if="layoutType === LAYOUT_CONFIG.GALLERY25" />
      <layoutLecturerColumn v-if="layoutType === LAYOUT_CONFIG.COLUMN" :is-show-menu-bar="isShowMenuBar"/>
      <layoutLecturerRow v-if="layoutType === LAYOUT_CONFIG.ROW" :is-show-menu-bar="isShowMenuBar"/>

      <!-- 音频 -->
      <!-- TODO: 这里确认下是否需要为自己创建audio标签，目前来看是不需要的 -->
      <audio
        autoplay
        v-for="(user) in audioList"
        :key="user.userId"
        :id="`audio-${user.userId}`"
        style="display: none;"
        class="audioPlayoutVolumeItem media-reset-flag"
      ></audio>
    </div>

    <!-- 底部操作区 -->
    <div @mouseover="overMenuBarHandle" data-html2canvas-ignore class="optionWrap" v-show="isShowMenuBar">
      <div class="left-option">
        <!-- 静音 -->
        <meetingAudioBtn @openSetting="openSetting(1)"></meetingAudioBtn>
        <!-- 扬声器 -->
        <meetingSpeakerBtn @openSetting="openSetting(1)"></meetingSpeakerBtn>
        <!-- 视频 -->
        <meetingVideoBtn @openSetting="openSetting"></meetingVideoBtn>
      </div>

      <div class="middle-section">
        <!-- 共享 -->
        <meetingShareBtn ref="shareBtn"></meetingShareBtn>

        <!-- 其他功能区 -->
        <div class="otherOption" v-if="isShowOtherOption">
          <!-- 录制 -->
          <meetingRecordBtn v-if="isShowRecorBtn"></meetingRecordBtn>

          <!-- 成员管理 -->
          <meetingUserManagerBtn></meetingUserManagerBtn>

          <!-- 聊天 -->
          <meetingChatBtn v-if="isShowChatBtn" ></meetingChatBtn>

          <!-- 议程 -->
          <div
            class="agenda-box"
            v-if="isShowAgendaBtn"
            @click="iframeOpen"
          >
            <my-icon
              :target="'agendaIcon'"
              :iconName="'iconyicheng_24_hei'"
            ></my-icon>
            <div class="agendaInfo">{{$t('meeting.agenda')}}</div>
          </div>

          <!-- 倒计时 -->
          <meetingCountdownBtn ref="meetingCountdownBtn" v-if="isShowCountdownBtn" @handleCount="handleCount"></meetingCountdownBtn>
          
          <!-- 截图至云端 -->
          <MeetinScreenHotBtn  v-if="(isHost || isCoHost) && [10007,10008].includes($configs.businessType*1)"></MeetinScreenHotBtn>

          <!-- 问卷 -->
          <div
             class="agenda-box wenjuanBtn"
             @click="wenjuanIframeOpen"
             v-if="[10008].includes($configs.businessType*1)"
           >
            <!-- <my-icon
              :target="'agendaIcon'"
              :iconName="'iconyicheng_24_hei'"
            ></my-icon> -->
            <img src="@/assets/images/iconyicheng_24_hei.png" width="24px" height="24px"/>
            <div class='circle' v-if="isShowWenjuanTips"></div>
            <div class="agendaInfo">问卷</div>
          </div>
        </div>
      </div>

      <!-- 结束会议 -->
      <meetingLogoutBtn ref="meetingLogoutBtn" @logoutMeeting="logoutMeeting" @hostEndMeeting="endingMeetingDialog = true"></meetingLogoutBtn>
    </div>

    <!-- 结束会议二次确认 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="endingMeetingDialog"
      width="380px"
    >
      <span>{{'是否结束会议'}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="endingMeetingDialog = false">{{$t('login.cancel')}}</el-button>
        <el-button type="primary" @click="hostEndMeeting">{{$t('login.end')}}</el-button>
      </span>
    </el-dialog>  

    <!-- 倒计时 -->
    <meetingCountdownTip
      :minutes="countDownTipMinutes"
      :seconds="countDownTipSeconds"
      :type="countDownTipType"
      @close="closeTip"
      @reset="resetTip"
    ></meetingCountdownTip>

    <!-- 断网提示 -->
    <div class="disconnect-box" v-if="isNetworkDisconnect">
      <div class="icon"></div>

      <div class="text">{{$t('meeting.instability')}}</div>
    </div>

    <!-- 会议设置弹窗 -->
    <meetingSettings ref="meetingSettings" :isMeeting="true" @openNetwork="isShowNetwork = true"></meetingSettings>

    <!-- 网络检测 -->
    <meetingNetworkBox @close="isShowNetwork=false" :isShowPanel="isShowNetwork" />

    <!-- 会议议程 -->
    <el-dialog
      v-dialogDrag
      custom-class="el-dialog-drag el-dialog-iframe"
      :title="$t('login.agenda')"
      :visible.sync="isOK"
      :modal="false"
      width="600px"
      @closed="iframeBeforeClosed"
      :append-to-body="true"
      :close-on-click-modal="true"
    >
      <span class="iframe-box">
        <iframe
          :src="webviewUrl"
          frameborder="0"
          name="showHere"
          scrolling="auto"
          width="100%"
          height="430px"
          class="iframe-item"
          @load="iframeLoad"
          v-if="webviewUrl"
        >
        </iframe>
        <span
          class="iframe-loading-box"
          v-if="iframeLoading"
        >
          <img
            src="~@/assets/images/agendas_loading_48.gif"
            alt=""
            srcset=""
            class="iframe-loading-img"
          >
        </span>
      </span>
    </el-dialog>

    <!-- toast提示 -->
    <div v-if="isShowToast" class="user-totast">{{ toastText }}</div>

    <!-- 水印 -->
    <div v-if="watermark" class="watermarkBox">{{watermark}}</div>

    <!-- 提示音 -->
    <audio class="media-reset-flag" id="userEnterTipAudio" style="display: none;" src="~@/assets/some_one_join_room.wav"></audio>

    <!-- 通用提示弹窗 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :visible.sync="commonTipDialog"
      width="380px"
    >
      <span>{{ commonTipText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="commonTipDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>

    <!-- 通用返回首页提示弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="commonBackDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('login.tips')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.enterError')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="goMeetIndex">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>

    <!-- 倒计时弹窗 -->
    <el-dialog
      :title="intervalTip"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="intervalDialog"
      width="380px"
    >
      <span>{{ intervalText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="intervalDialog = false">{{$t('login.know')}}({{ removeTime }}s)</el-button>
      </span>
    </el-dialog>

    <!-- 被强制踢出会议 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="exitMeetDialog"
      width="380px"
    >
      <span>{{ exitMeetText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shortlyExitMeet">{{$t('login.know')}}({{ removeTime }}s)</el-button>
      </span>
    </el-dialog>

    <!-- 是否开启音频 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="unMuteAudioDialog"
      width="380px"
    >
      <span>{{$t('meeting.pleacenoVoice')}}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resumeAudioDialogHandle">{{$t('meeting.audioUnMute')}}</el-button>
        <el-button type="primary" @click="unMuteAudioDialog = false">{{$t('meeting.keepQuiet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 连接超时 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="networkTimeoutDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('meeting.abnormality')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.exception')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="reJoinMeetHandle" v-if="$configs.businessType != 10007">{{$t('seeting.joinAgain')}}</el-button>
        <el-button type="primary" @click="logoutMeetHandle">{{$t('login.leaveMeet')}}</el-button>
      </span>
    </el-dialog>

    <!-- 共享中断弹窗 -->
    <el-dialog
      custom-class="commonElDialog"
      width="380px"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible.sync="shareStopDialog"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">{{$t('login.tips')}}</div>
      </div>

      <div class="dialog-content">
        {{$t('meeting.againShare')}}
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="shareStopDialog = false">{{$t('login.know')}}</el-button>
      </span>
    </el-dialog>

    <!-- 文档列表 -->
    <el-dialog
      v-dialogDragResizable
      custom-class="border-dialog doc-list-dialog"
      :modal="false"
      :visible.sync="isShowDocumentPanel"
      width="600px"
      title="文档列表"
      :append-to-body="true"
      :close-on-click-modal="false"
    >
      <documentList ref="documentMember" v-if="isShowDocumentPanel"></documentList>
    </el-dialog>

    <!-- 上传组件 -->
    <el-upload
      v-show="0"
      :show-file-list="false"
      :multiplet="false"
      accept=".ppt,.pptx"
      class="upload-demo"
      ref="upload"
      action=""
      :http-request="fileUploadFn"
      :before-upload="beforeUpload"
      :onProgress="handleChangeProgress"
      >
        <el-button size="small" id="uploadBtnElement" data-type="" type="success">上传文件</el-button>
    </el-upload>

    <!-- 阅后即焚提示弹窗 -->
    <el-dialog :visible.sync="docFireDialog" width='320px' title="提示" :closable="false" custom-class="doc-fire-dialog" :footer="null" >
      <div class="text">课程结束后PPT自动云端删除，不存在泄露风险</div>
      <div class="bottom">
          <el-checkbox v-model="docFireDialogChecked" @change="changeDocFireDialogChecked">以后不在提示</el-checkbox>
          <div class="btn" id="docFireDialogBtn" @click="queryFileFire">我知道了</div>
      </div>
    </el-dialog>

        
    <!-- 用后即焚文档上传进度 -->
    <el-dialog
      class="uploadProgres"
      width="370px"
      :visible.sync="isUploading" 
      title="加载中"
      :footer="null"
      :keyboard="false"
      :maskClosable="false"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      wrapClassName="doc-upload-progress"
    >   
        <div class="upload-progress-tips">加载过程中请勿关闭窗口，否则会失败</div>
        <el-progress class="elProgress" :stroke-width="16" :percentage="uploadPercent"></el-progress>
    </el-dialog>

    <!-- edge浏览器播放不了音频 -->
    <el-dialog
      :title="$t('login.tips')"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="edgePlayError"
      width="380px"
    >
      <span>会议检测音视频播放受限影响会中接收音视频信号，请在浏览器设置中搜索“媒体自动播放”，点选允许自动播放网站上的音视频。或使用chrome浏览器参会。</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="edgePlayError=false">确定</el-button>
      </span>
    </el-dialog>

  <!-- 问卷 -->
  <el-dialog
    v-dialogDrag
    custom-class="el-dialog-drag el-dialog-iframe wenjuann-dialog-drag"
    :visible.sync="isOpenWenjuan"
    :modal="false"
    title="问卷"
    width="600px"
    @closed="iframeBeforeClosed"
    :append-to-body="true"
    :close-on-click-modal="true"

  >
    <span class="iframe-box">
      <iframe
        :src="questionnaireUrl"
        frameborder="0"
        name="showHere"
        scrolling="auto"
        width="100%"
        height="430px"
        class="iframe-item"
        @load="iframeLoad"
        v-if="questionnaireUrl"
      >
      </iframe>
      <span
        class="iframe-loading-box"
        v-if="iframeLoading"
      >
        <img
          src="~@/assets/images/agendas_loading_48.gif"
          alt=""
          srcset=""
          class="iframe-loading-img"
        >
      </span>
    </span>
  </el-dialog>

    <!-- 问卷询问 -->
    <el-dialog
      title="收到问卷"
      class="commonElDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
      :visible.sync="isOpenWenjuanAsk"
      width="380px"
    >
      <span>收到主持人向您发送的问卷，是否现在前往填写</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isOpenWenjuanAsk=false,isShowWenjuanTips = true">稍后填写</el-button>
        <el-button type="primary" @click="questionnaireUrl = `${questionnaireUrlStr}&roleCode=${selfInfo.roleCode}`,isOpenWenjuan=true,isOpenWenjuanAsk=false,isShowWenjuanTips=false">前往</el-button>
      </span>
    </el-dialog>
 
  </div>
</template>

<script>
import Vue from 'vue'
import { loganLog } from "@/utils/log"

import screenfull from "screenfull"
import throttle from 'lodash.throttle'

import MeetingManager from '@/lib/core/meetingManager'
import ExceptionManager from '@/lib/core/exceptionManager'
import DocumentManager from '@/lib/core/documentManager'

import { QRTCQuality } from "@/meet-sdk/module/rtc/lib/RoomParams"

import { getUuid, getAvatar, getAcceptLanguage, setMeetInfo, setDocFireDialog,getMopanMeetToken} from "@/utils/auth";
import { getLocalDeviceInfo } from "@/utils/device"
import { countDownTimer } from "@/utils/countDown";
import { strMiddleEllipsis } from "@/utils/str"
import { isSafari } from "@/utils/mobile"

import { ROLE_CODE, CONTROL_COMMAND, LAYOUT_CONFIG } from "@/constant/index";
import { meetAgendasUrl } from "@/config/index";

import { fetchReconnectionInfo, joinConference, fetchMeetControl, fetchConference, fetchConferenceSnowflake, queryDocumentShareData, documentDisplayName, queryDocFileData ,getHostRedirect,landingConfirm} from '@/api/meet'

import LayoutGallery from "./Meeting.Layout.Gallery.vue";
import LayoutGalleryMCU from "./Meeting.Layout.GalleryMCU.vue";
import LayoutLecturerRow from "./Meeting.Layout.Lecturer.Row.vue";
import LayoutLecturerColumn from "./Meeting.Layout.Lecturer.Column.vue";

import MeetingTopLeftInfo from "./top/Meeting.Left.Info.vue";
import MeetingTopLeftSafety from "./top/Meeting.Left.Safety.vue";
import MeetingTopLeftNetwork from "./top/Meeting.Left.Network.vue";
import MeetingTopLeftRecord from "./top/Meeting.Left.Record.vue";

import MeetingTopRightUploadLog from "./top/Meeting.Right.UploadLog.vue";
import MeetingTopRightHelp from "./top/Meeting.Right.Help.vue";
import MeetingTopRightKeFu from "./top/Meeting.Right.KeFu.vue";
import MeetingTopRightTime from "./top/Meeting.Right.Time.vue";
import MeetingTopRightChangeLayout from "./top/Meeting.Right.ChangeLayout.vue";
import MeetingTopRightScreenfull from "./top/Meeting.Right.Screenfull.vue";

import MeetingAudioBtn from "./buttom/Meeting.AudioBtn.vue";
import MeetingSpeakerBtn from "./buttom/Meeting.SpeakerBtn.vue";
import MeetingVideoBtn from "./buttom/Meeting.VideoBtn.vue";
import MeetingShareBtn from "./buttom/Meeting.ShareBtn.vue";
import MeetingLogoutBtn from "./buttom/Meeting.LogoutBtn.vue";
import MeetingRecordBtn from "./buttom/Meeting.RecordBtn.vue";
import MeetingUserManagerBtn from "./buttom/Meeting.UserManagerBtn.vue";
import MeetingChatBtn from "./buttom/Meeting.ChatBtn.vue";
import MeetingCountdownBtn from "./buttom/Meeting.CountdownBtn.vue";
import MeetingCountdownTip from "./buttom/Meeting.CountDownTip.vue";
import MeetinScreenHotBtn from "./buttom/Meeting.screenHot.vue";


import MeetingSettings from "./Meeting.Settings.vue";
import MeetingNetworkWindow from "./Meeting.Network.Window.vue";
import { DIALOG_BACK_ERR_CODE } from "@/constant/index"
import dayjs from 'dayjs'
import Logan from "logan-web";
import { reportLogUrl } from '@/config/index';
import eventBus from '@/utils/bus'

import DocumentList from "@/components/documentList/documentList";

export default {
  components: {
    layoutGallery: LayoutGallery,
    layoutGalleryMCU: LayoutGalleryMCU,
    layoutLecturerRow: LayoutLecturerRow,
    layoutLecturerColumn: LayoutLecturerColumn,

    meetingTopLeftInfo: MeetingTopLeftInfo,
    meetingTopLeftSafety: MeetingTopLeftSafety,
    meetingTopLeftNetwork: MeetingTopLeftNetwork,
    meetingTopLeftRecord: MeetingTopLeftRecord,

    meetingTopRightUploadLog: MeetingTopRightUploadLog,
    meetingTopRightHelp: MeetingTopRightHelp,
    meetingTopRightKeFu: MeetingTopRightKeFu,
    meetingTopRightTime: MeetingTopRightTime,
    meetingTopRightChangeLayout: MeetingTopRightChangeLayout,
    meetingTopRightScreenfull: MeetingTopRightScreenfull,

    meetingAudioBtn: MeetingAudioBtn,
    meetingSpeakerBtn: MeetingSpeakerBtn,
    meetingVideoBtn: MeetingVideoBtn,
    meetingShareBtn: MeetingShareBtn,
    meetingLogoutBtn: MeetingLogoutBtn,
    meetingRecordBtn: MeetingRecordBtn,
    meetingUserManagerBtn: MeetingUserManagerBtn,
    meetingChatBtn: MeetingChatBtn,
    meetingCountdownBtn: MeetingCountdownBtn,
    meetingCountdownTip: MeetingCountdownTip,
    MeetinScreenHotBtn: MeetinScreenHotBtn,


    meetingSettings: MeetingSettings,
    meetingNetworkBox: MeetingNetworkWindow,

    documentList: DocumentList
  },

  data() {
    return {
      waitLoading: null,

      LAYOUT_CONFIG,
      // isNeedDisconnect: true, // 需要清理
      isNeedDisconnect: true, // 需要清理

      maturityTimer: null, // 免费时长
      maturityTimeStr: '', // 免费时长字符串

      isShowToast: false,
      toastText: '',
      toastTimer: null,

      // 离开会议
      removeTimer: null,
      removeTime: 3,
      exitMeetDialog: false,
      exitMeetText: "",

      // 通用弹窗提示
      commonTipDialog: false,
      commonTipText: "",


      // 通用返回首页弹窗提示
      commonBackDialog: false,

      // 连接超时弹窗
      networkTimeoutDialog: false,

      // 请求解除静音
      unMuteAudioDialog: false,

      // 提供共享停止弹窗
      shareStopDialog: false,

      agendaId: "", // 议程ID
      agendaPermission: 0, // 会议权限

      countDownTipMinutes: 0, // 倒计时提示框分钟
      countDownTipSeconds: 0, // 倒计时提示框秒钟
      countDownTipType: "-", // 倒计时类型

      // 顶部和底部操作栏
      menuBarTimer: null,
      isShowMenuBar: true,

      webviewUrl: "",
      isOK: false,
      iframeLoading: true,

      // 网路检测
      isShowNetwork: false,
      networkInfo: {},

      // 退房锁定
      isLockExitRoom: false,

      // 云录制相关
      intervalTip : '',
      intervalText : '',
      intervalDialog : false,

      //结束会议二次确认弹窗
      endingMeetingDialog:false,

      preRouterName:'',

      isShowRecorBtn: true, //是否显示录制按钮
      isShowAgendaBtn: true, //是否显示议程按钮
      isShowChatBtn: true, //是否显示聊天按钮
      isShowCountdownBtn: true, //是否显示倒计时按钮
      isShowOtherOption: true, //是否显示Option

      isShowDocumentPanel: false, // 是否显示文档列表

      docFireDialog: false, //用后即焚弹窗
      docFireDialogChecked:false, //阅后即焚弹窗记忆

      isUploading: false,//上传进度条
      edgePlayError: false,//edge浏览器播放不了音频
      uploadStatus:'uploading',
      uploadPercent: 0,


      isShowWenjuanTips: false, //是否显示提示
      questionnaireUrl:'',//问卷地址
      questionnaireUrlStr:'',
      isOpenWenjuan:false,//是否打开问卷
      isOpenWenjuanAsk:false,//问卷询问
      mopanInfo:{},
      mopanLink:'',
      watermark:'',//水印
      mopanRedirectUrl:''//代表台回退地址
    };
  },

  computed: {
    isFullModel() {
      return this.$store.state.meet.isFullModel
    },
    isNetworkDisconnect() {
      return this.$store.state.meet.isNetworkDisconnect
    },
    isShowTime(){
      return this.$store.state.meet.joinDuration
    },
    layoutType() { // 布局模式
      return this.$store.state.meet.layoutType;
    },

    userList() { // 成员列表
      return this.$store.getters["member/getRealUserList"]
    },

    audioList() { // 音频队列
      //todo audioList17路没限制住
      return this.$store.state.member.audioList
    },

    selfInfo() {
      const userId = this.$route.params.userID;
      const roomId = this.$route.query.roomID;

      return this.$store.getters["member/getUser"](`${roomId}_${userId}`)
    },

    isHost() {
      return this.selfInfo.roleCode === ROLE_CODE.HOST;
    },

    isCoHost() {
      return this.selfInfo.roleCode === ROLE_CODE.CO_HOST;
    },

    isOrdinaryUser() {
      return this.selfInfo.roleCode === ROLE_CODE.USER;
    },

    isUserRecording() {
      return this.userList.find(item => {
        return item.isRecord && !item.recordPaused
      })
    },
    isExitUserShare() { // 存在用户共享
      return this.userList.find(i => i.isShare)
    },
    sharePermission() {
      return this.$store.state.meet.sharePermission;
    },
    isShareDoc() {
      return this.$store.state.meet.isShareDoc
    },
    shareType() {
      return this.$store.state.meet.shareType
    },
  },
  watch: {
    // isUploading(newValue){
    //   if(!newValue && this.uploadStatus === 'uploading'){
    //     console.error('xxxxxxxxxxxxxxxxxx todo')
          // this.uploadPercent = 0;
          // // 取消请求
          // try {
          //     service.document.uploadFileFn({cancel: true})
          // } catch (error) {
              
          // }
      // }
    // }
  },
  async created() {
    //底部工具栏
    this.calculateWidth()

    // 初始化摄像头设备
    this.initCurrentCameraDevice()
    const routeQuery = this.$route.query
    let businessType = routeQuery.businessType
    let userId = this.$route.params.userID
    let roomId = routeQuery.roomID
    let conferenceNo = routeQuery.conferenceNo
    // 设置configs值
    this.$configs.userId = userId;
    this.$configs.roomId = roomId;
    this.$configs.userName = decodeURIComponent(routeQuery.userName)
    this.$configs.avatarUrl = getAvatar()
    this.$configs.peerId = roomId + '_' + userId;
    this.$configs.conferenceNo = conferenceNo;
    this.$configs.businessType = businessType;
    if(routeQuery.hospital){
      let hospital = decodeURIComponent(routeQuery.hospital)
      this.$configs.hospital = hospital;
    }

    !isSafari() && window.addEventListener('storage', this.handleStorageEvent)

    this.initScreenfullEvent()

    if(this.$configs.businessType == 10008){
      this.getQuestionnaireUrl()
    }
    
    this.initEnterHandle()//监听全屏时快捷键事件

    this.waitLoading = this.$loading({
      lock: true,
      text: this.$t('meeting.goMeeting'),
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)',
      customClass: 'waitMeetLoading'
    })
     //设置休眠不断
    this.noSleep = new this.$noSleep();
    
    this.bindNoSleep()

    try {
      const resData = await fetchReconnectionInfo({
        userId: userId,
        deviceId: getUuid(),
        conferenceNo: conferenceNo,
        roomId: roomId
      })
      
      !isSafari() && window.localStorage.setItem('exit-msg', new Date().getTime())

      /**
       * attendList中的数据来源：
       * 用户本人和房间内其他用户的信息都有可能返回，只要满足以下任意一个条件：
       * 1. 不是普通用户
       * 2. 正在举手
       * 3. 正在录制
       */
      const {
        attendList,
        conference,
        roleCode
      } = resData;
      let metaData

      if(conference.metaData){
        metaData = JSON.parse(conference.metaData)
      }

      const conferenceToken = resData['X-Conference-Token']
      const channelToken = resData['X-Channel-Token']

      setMeetInfo(
        conferenceToken,
        channelToken
      )

      this.$router.replace({
        query: {
          ...this.$route.query,
          conferenceNo: conference.conferenceNo
        }
      })

      //从缓存配置中获取 是否显示参会时长
      const localDeviceData = getLocalDeviceInfo()
      const joinDuration = localDeviceData.joinDuration
      //从缓存配置中获取 是否隐藏非视频参会者设置选项
      const hideNoVideoMember = localDeviceData.hideNoVideoMember
      let isFocusScreen = 0

      if (Array.isArray(attendList) && attendList.length > 0) {
        attendList.forEach((user) => {
          // raiseHandStatus 举手状态 1:举手 0：手放下
          // recordStatus 录制状态 1：已录制  0：停止录制
          const { peerId, raiseHandStatus, recordStatus, roleCode, focusStatus } = user;

          const stateInfo = {
            isRaiseHand: !!raiseHandStatus,
            isRecord: !!recordStatus,
          };

          this.$configs.attendMap[peerId] = {
            userId: peerId,
            roleCode: Number(roleCode),
            ...stateInfo,
          };

          if(focusStatus === 1){
            isFocusScreen = peerId
          }
        });
      }

      // console.error(11111112233,metaData)

      // 存储全局会议状态
      this.$store.commit("meet/updateGlobalMeetState", {
        allowEarlyEntry: conference.allowEarlyEntry,
        muteJoinMeeting: conference.muteJoinMeeting,
        playTips: conference.playTips,
        allowSelfUnmute: conference.allowSelfUnmute,
        ownerPasswordEnable: conference.ownerPasswordEnable,
        passwordEnable: conference.passwordEnable,
        agendaPermission: conference.agendaPermission,
        allMuteState: conference.allMuteState,
        recordPermission: conference.recordPermission,
        sharePermission: conference.sharePermission,
        lockedState: conference.lockedState,

        ownerName: conference.ownerName,
        ownerId: conference.ownerId,
        links: conference.links,
        userRoleCode: Number(roleCode),
        meetName: conference.title,
        password:conference.password,
        joinDuration,
        isFocusScreen:isFocusScreen,
        cloudRecorState:conference.cloudRecordState,
        hostRedirectUrl:metaData ? metaData.hostRedirectUrl : '',
        RedirectUrl:metaData ? metaData.RedirectUrl : ''
      });

      this.$store.commit('member/updateHideNoVideoMember', hideNoVideoMember)

      // 链接im
      this.$i100MeetSDK.i100IM.enterRoom({
        roomid: roomId,
        channelToken
      })

      // 初始化会议sdk
      this.initMeetControl({
        conferenceToken
      })

      // 检查共享内容
      this.checkSharing()

      //上报数据
      // this.runShenceTimer();
    } catch (error) {
      this.isNeedDisconnect = false
      // console.error(444442,error)
      this.waitLoading.close()

      let describe  = error.message? `错误信息:${error.message}` :  error.msg? `错误信息:${error.msg}` : ''
      if (error.message && error.message === 'Network Error') {
        describe = this.$t('meeting.netWorkBad')
      }
      const commonText  = error.code?`错误码:${error.code}`: this.$t('meeting.enterError')
      this.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo: {
          tips: error.code? "加入会议失败" : "",
          isGoIndex: true,
          showClose: false,
          describe:describe,
          commonText: commonText
        }
      })
    }

    window.addEventListener('resize', this.calculateWidth)

    window.addEventListener('visibilitychange',this.calculateWidth)


    window.addEventListener('offline', () => {
      if(this.isUploading === true){
          this.showToast('网络异常，请重新上传')
          this.uploadPercent = 0
          this.isUploading = false;
      }
    })
  },
  methods: {
    async checkSharing(){
      const fileData = await queryDocumentShareData({
          roomid: this.$route.query.roomID
      })
      console.error('检查共享区域内容',fileData,this.$configs.peerId)
      if(fileData && fileData.id && this.$configs.userId===fileData.userId){
        loganLog(`入会后发现云共享的是自己恢复云共享`)
        //查询文件信息 获取是否阅后即焚
        const docData = await queryDocFileData({
            fileId: fileData.fileId
        })
        this.$nextTick(() => {
          // 自动转到成员列表格式
          this.$store.commit("meet/updateGlobalMeetState", {
            layoutType: LAYOUT_CONFIG.COLUMN,
            isShareDoc:true,
            shareFireId:fileData.fileId,
            shareDocTotalPage:fileData.totalPage,
            shareType:(docData.saveForever)?1:2,
            curPage:fileData.curPage
          });
          this.$nextTick(() =>
            this.$documentManager.startDocRecovery(fileData,this.$configs.userId)
          );
        })
      }
    },
    // 上传文件前处理
    beforeUpload (file) {
        const isLt100M = file.size / 1024 / 1024
        if (isLt100M > 100) {
            this.$message.error('文件大小100M以内');
            return false;
        }
    },

    fileUploadFn (options) {
        let { file, onProgress, onSuccess, onError } = options;
        // let this.shareType = this.shareType // 1 阅后即焚 2 我的文档
        let upType = document.getElementById('uploadBtnElement').dataset.upType // 0 我的文档 1：非个人会议号文档 2: 个人会议号文档 

        if(file.name.length >= 100){
          const littleName = file.name.substr(-80)
          const copyFile = new File([file], littleName, {
            type: file.type,
          })
          copyFile.uid = file.uid
          file = copyFile
        }
        const routeQuery = this.$route.query
        console.error('上传文档----shareType:',this.shareType,'--upType:',upType)
        let params= {
            file:file,
            userId: this.$configs.userId,
            saveForever: this.shareType == 2 ? false : true,
            type: this.shareType == 2 ? 0 : upType ? upType : 0, //0：我的文档 1：非个人会议号文档 2: 个人会议号文档 
            conferenceId: routeQuery.conferenceId,
            conferenceNo: this.$configs.conferenceNo
        }

        if(this.shareType == 2){ 
          this.isUploading = true;
        } else {  
          let _data = Object.assign(file,{docType:0,status:"uploading",percent:0,createTime:new Date()})
          this.$refs.documentMember.fileList.unshift(_data);
        }
        
        this.$documentManager.uploadFileFn( params, (uploadProgress)=>{
            if(this.shareType == 2){
              this.uploadPercent = 0
            }
            onProgress({ percent: Math.round((uploadProgress.loaded / uploadProgress.total) * 100).toFixed(2) }, file)
        })
        .then((res) => {
            // console.error(33334444,res)
            if(this.shareType == 2){ //阅后即焚的上传完成后start
              let _data= { 
                id: res.id,
                isShareDoc: this.isShareDoc,//当前有没有云共享
                meetInfo: {userName: this.$configs.userName,roomId: this.$configs.roomId,userId: this.$configs.userId,},
                officeUrl:res.officeUrl,
                businessCode:res.businessCode,
                totalPage:res.totalPage,
              }
              this.$documentManager.queryFile(_data)
            }else{
              if(res.repeat){
                this.showToast('重复文件，已过滤')
              }
              //刷新文档列表
              this.$refs.documentMember.getDocList()
            }
            onSuccess({ ...res, name: file.name, status: 'done' }, file)
        })
        .catch(onError)
    },

          
    handleChangeProgress(info,file){
        // console.error(8888,info,file)
        if(info && info.percent){
            let percent = Number(Number(info.percent).toFixed());
            if(this.shareType == 2){
              this.uploadPercent = percent === 100 ? 99 : percent;
              if(percent >= 100){
                setTimeout(() => {
                  this.isUploading = false;
                  this.uploadPercent = 0
                }, 1000);
              }
            }else{
              this.$refs.documentMember.handleChangeProgress(Object.assign(file,{docType:0,status:"uploading",percent:percent,createTime:new Date()}))
            }
        }

        // let uploadStatus = info.file.status; // 上传状态
        // this.uploadStatus = uploadStatus;
        // switch (uploadStatus) {
        //     case 'uploading':
        //         if(info.event && info.event.percent){
        //             let percent = Number(Number(info.event.percent).toFixed());
        //             this.uploadPercent = percent === 100 ? 99 : percent;
        //         }
        //         break;
        //     case 'done':
        //         this.isUploading = false;
        //         alert(`${info.file.name} 上传成功`);
        //         break;
        //     case 'error':
        //         this.isUploading = false;
        //         alert(`${info.file.name} 上传失败`);
        //         break;
            
        //     default:
        //         break;
        // }
    },
    async initCurrentCameraDevice(){
      // 初始化摄像头设备
      try {
        await this.$deviceControl.initCurrentCameraDevice()
      } catch (error) {
        loganLog(`初始化摄像头失败-----error:${JSON.stringify(error)}`)
      }
    },
    calculateWidth(){
      let _clientWidth = document.body.clientWidth
      // console.log('run calculateWidth')
      if(_clientWidth < 1240){
        let _gap = 1240 - _clientWidth
        if(_gap > 90) this.isShowRecorBtn = false //录制按钮
        else this.isShowRecorBtn = true

        if(_gap > 190) this.isShowCountdownBtn = false //倒计时按钮
        else this.isShowCountdownBtn = true

        if(_gap > 270) this.isShowAgendaBtn = false //议程按钮
        else this.isShowAgendaBtn = true

        if(_gap > 350) this.isShowChatBtn = false //聊天按钮
        else this.isShowChatBtn = true


        if(_gap > 470) this.isShowOtherOption = false //按钮warp
        else this.isShowOtherOption = true
      } else{
        this.isShowRecorBtn = true
        this.isShowCountdownBtn = true
        this.isShowAgendaBtn = true
        this.isShowChatBtn = true
        this.isShowOtherOption = true
      }
      // 分辨率变化弹窗居中
      let dialogNode = document.querySelectorAll('.el-dialog-drag')
      dialogNode.forEach(element => {
        if(element.parentNode.style.display != 'none'){
          element.style.top = 0
          element.style.left = 0
        }
      });
    },
    initEnterHandle() {
      window.addEventListener('keydown', this.keydownHandle)
    },

    changeDocFireDialogChecked(){
      setDocFireDialog(this.docFireDialogChecked)
    },
    queryFileFire(){
      setDocFireDialog(this.docFireDialogChecked)
      let _element = document.getElementById('uploadBtnElement');
      _element && _element.click()
      this.docFireDialog = false
    },

    keydownHandle(e){
      if(e.ctrlKey && e.altKey && e.keyCode == 72){
        this.isShowMenuBar = !this.isShowMenuBar
      }
    },
    removeEnterHandle(){
      window.removeEventListener('keydown', this.keydownHandle)
    },

    // 共享权限
    isOneySharePermis() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.showToast(this.$t('meeting.netWorkBad'))
        return false
      }
      console.error('isOneySharePermis',this.isExitUserShare)
      if (this.isExitUserShare) { // 当前有人再共享 TODO: 先临时这样做
        if(this.isShareDoc){ //如果自己是云分享
          this.$refs.shareBtn.stopShareDialog = true;
          this.$refs.shareBtn.stopAndStartShare = false;
          return false
        }

        if (this.isHost) { //如果自己是主持人
            if(this.isExitUserShare.userId == this.$configs.peerId){
              this.$refs.shareBtn.stopAndStartShare = false;
            }else{
              this.$refs.shareBtn.stopAndStartShare = true;
            }
            this.$refs.shareBtn.stopShareDialog = true;
        } else if (this.isCoHost) { //如果自己是联席主持人
          if (this.isExitUserShare.roleCode === ROLE_CODE.HOST) { //如果正在分享人是主持人
            this.commonTipText = this.$t('meeting.hostShare')
            this.commonTipDialog = true
          } else { //正在分享人是联席或普通
            if(this.isExitUserShare.userId == this.$configs.peerId){
              this.$refs.shareBtn.stopAndStartShare = false;
            }else{
              this.$refs.shareBtn.stopAndStartShare = true;
            }
            this.$refs.shareBtn.stopShareDialog = true;
          }
        } else {
          this.commonTipText = this.$t('meeting.haveShare')
          this.commonTipDialog = true
        }

        return false
      } else { // 当前没有人再共享
        let isRun = false
        if (this.sharePermission) { // 需要共享权限的时候
          if (!this.isOrdinaryUser) { // 自己是主持人或者联席主持人
            console.error('需要共享权限，自己是主持人')
            isRun = true
          } else {
            this.commonTipText = this.$t('meeting.getShareIng')
            this.commonTipDialog = true
          }
        } else {
          console.error('不需要共享权限')
          isRun = true
        }
        return isRun
      }
    },

    // 获取问卷地址
    async getQuestionnaireUrl(){
      try {
        const resData = await landingConfirm({
          token: getMopanMeetToken()
        })
        // console.error(88888888,resData.redirectUrl)
        this.mopanLink = resData.redirectUrl
        this.questionnaireUrl = resData.info.questionnaireUrl
        this.questionnaireUrlStr = resData.info.questionnaireUrl
        if(resData.project && resData.project == 20){
          this.mopanInfo = resData.info
        }
        if(resData.info.onlineWatermark){
          let time = new Date()
          let y = time.getFullYear();
          let m = time.getMonth() + 1;
          let d = time.getDate();
          this.watermark =resData.info.mobile + resData.info.username + (y-2000)+'年'+m+'月'+d+'日'
        }
        if(resData.redirectUrl){
          this.mopanRedirectUrl = resData.redirectUrl
        }
      } catch (error) {
        loganLog(`获取Confirm信息失败-----error:${JSON.stringify(error)}`)

        setTimeout(() => {
          this.getQuestionnaireUrl()
        }, 1000);
      }
    },

    //数据上报
    runShenceTimer(){
      console.error('sensors1')

      this.shenceDataTimer = setInterval(() => {
        const _localMediaStats = this.$i100MeetSDK.i100MeetingControl.getLocalMediaStats()
        const _remoteMediaStats = this.$i100MeetSDK.i100MeetingControl.getRemoteMediaStats()
        const _isUseHuaTong = this.selfInfo.isUseHuaTong
        const _isUseShiPin = this.selfInfo.isUseShiPin
        const _isShare = this.selfInfo.isShare

        // console.error('isUseHuaTong:',_isUseHuaTong)
        // console.error('isUseShiPin:',_isUseShiPin)
        // console.error('isShare:',_isShare)
        // console.error('getLocalMediaStats:',_localMediaStats)
        // console.error('getRemoteMediaStats:',_remoteMediaStats)

        let trackData = {
          uar : _localMediaStats[1] ? _localMediaStats[1].packetBitrate : '',  // 上行音频速率
          uaplr : _localMediaStats[1] ? _localMediaStats[1].packetsLostRatio : '', // 上行音频丢包率
          uad : _localMediaStats[1] ? _localMediaStats[1].jitterBufferDelay : '',// 上行音频延迟
          uanj : _localMediaStats[1] ? _localMediaStats[1].jitter : '', // 上行音频网络抖动
          uvr : _localMediaStats[0] ? _localMediaStats[0].packetBitrate : '', // 上行视频速率
          uvplr : _localMediaStats[0] ? _localMediaStats[0].packetsLostRatio : '', // 上行视频丢包率
          uvd : _localMediaStats[0] ? _localMediaStats[0].jitterBufferDelay : '', // 上行视频延迟
          uvnj : _localMediaStats[0] ? _localMediaStats[0].jitter : '', // 上行视频网络抖动
          uvfr : _localMediaStats[0] ? _localMediaStats[0].framesPerSecond : '', // 上行视频帧率
          usr : _localMediaStats[2] ? _localMediaStats[2].packetBitrate : '', // 上行共享速率
          usplr : _localMediaStats[2] ? _localMediaStats[2].packetsLostRatio : '', // 上行共享丢包率
          usd : _localMediaStats[2] ? _localMediaStats[2].jitterBufferDelay : '', // 上行共享延迟
          usnj : _localMediaStats[2] ? _localMediaStats[2].jitter : '', // 上行共享网络抖动
          usfr : _localMediaStats[2] ? _localMediaStats[2].framesPerSecond : '', // 上行共享帧率

          dar : _remoteMediaStats[1] ? _remoteMediaStats[1].packetBitrate : '', // 下行音频速率
          daplr : _remoteMediaStats[1] ? _remoteMediaStats[1].packetsLostRatio : '', // 下行音频丢包率
          dad : _remoteMediaStats[1] ? _remoteMediaStats[1].jitterBufferDelay : '', // 下行音频延迟
          danj : _remoteMediaStats[1] ? _remoteMediaStats[1].jitter : '', // 下行音频网络抖动
          
          dvr : _remoteMediaStats[0] ? _remoteMediaStats[0].packetBitrate : '', // 下行视频速率
          dvplr : _remoteMediaStats[0] ? _remoteMediaStats[0].packetsLostRatio : '', // 下行视频丢包率
          dvd : _remoteMediaStats[0] ? _remoteMediaStats[0].jitterBufferDelay : '', // 下行视频延迟
          dvnj : _remoteMediaStats[0] ? _remoteMediaStats[0].jitter : '', // 下行视频网络抖动
          dvfr : _remoteMediaStats[0] ? _remoteMediaStats[0].framesPerSecond : '', // 下行视频帧率
          
          dsr : _remoteMediaStats[2] ? _remoteMediaStats[2].packetBitrate : '', // 下行共享速率
          dsplr : _remoteMediaStats[2] ? _remoteMediaStats[2].packetsLostRatio : '', // 下行共享丢包率
          dsd : _remoteMediaStats[2] ? _remoteMediaStats[2].jitterBufferDelay : '', // 下行共享延迟
          dsnj : _remoteMediaStats[2] ? _remoteMediaStats[2].jitter : '', // 下行共享网络抖动
          dsfr : _remoteMediaStats[2] ? _remoteMediaStats[2].framesPerSecond : '', // 下行共享帧率

          as : _isUseHuaTong ? 1 : 0, // 音频开启状态
          vs : _isUseShiPin ? 1 : 0, // 视频开启状态
          ss : _isShare ? 1 : 0, // 共享开启状态
        }

        // console.error('isUseHuaTong:',_isUseHuaTong)
        // console.error('isUseShiPin:',_isUseShiPin)
        console.error('sensors:',trackData)

        this.$sensors.track('MeetingApp', {
          ...trackData
        });
      }, 15000);
    },

    /**
     * storage events
     */
    handleStorageEvent(e) {
      console.error('收到storage events')

      if (e.key === 'exit-msg') {
        this.exitMeetByTimer("您已在其他页签加入，当前页签被移出会议")
      }
    },
    
    bindNoSleep() {
      let that = this
      document.addEventListener('click', function enableNoSleep() {
        document.removeEventListener('click', enableNoSleep, false);
        that.noSleep.enable();
      }, false);
    },

    goMeetIndex() {
      this.commonBackDialog = false
      this.$router.push('/')
    },

    /**
     * 监听点击事件
    */
    
    /**
     * 初始化会议sdk
     */
    async initMeetControl(options = {}) {
      // 初始化sdk
      Vue.prototype.$meetingControl = new MeetingManager(this)

      // 初始化订阅异常处理单元
      Vue.prototype.$exceptionManager = new ExceptionManager(this)
      Vue.prototype.$documentManager = new DocumentManager(this)

      // 初始化sdk事件
      this.initMeetControlEvent()

      // 进房
      try {
        await this.$meetingControl.enterRoom(options)
        loganLog('进房成功----')
        this.waitLoading.close()
      } catch (error) {
        this.isNeedDisconnect = false
        this.waitLoading.close()
        console.error(44441,error)
        loganLog(`进房失败-----error:${JSON.stringify(error)}`)
        //1.6新增SDK异常处理，避免多次处理进房失败
        if(JSON.stringify(error) == '{}' || !error || error.errorCode!== undefined && !(DIALOG_BACK_ERR_CODE.includes(error.errorCode))){
          this.commonBackDialog = true
        }
        const { peerId, userName } = this.$configs
        this.$sentry.captureException({
          msg: '进房失败',
          userId: peerId,
          userName,
          error
        })
        return
      }

      //开启焦点画面
      let isFocusScreen = this.$store.state.meet.isFocusScreen
      if(isFocusScreen!=0){ 
        try { //开启焦点画面
          loganLog('入会后开启焦点画面')
          await this.$meetingControl.handleFocusScreen(isFocusScreen);
        } catch (error) {
          loganLog(`开启焦点画面失败---_id${isFocusScreen}---error${error}`)
        }
      }

      //获取实时音量
      this.getAudioLevelsFn()
    },
    //轮询获取实时音量
    getAudioLevelsFn(){
      this.getAudioLevelsTimer = setInterval(() => {
        this.$meetingControl.getAudioLevelsFn()
      }, 500);
    },
    /**
     * 初始化sdk事件
     */
    initMeetControlEvent() {
      // 注册web-sdk改名监听事件
      this.initChangeNameEvent();

        // 注册web-sdk踢人监听事件
      this.initUserExitRoomEvent()

      // 注册web-sdk会控（customCommand）监听事件
      this.initMeetingControlEvent();

      // 注册web-sdk 错误事件
      this.initMeetingErrorEvent()

      // 注册web-sdk 网络检测
      this.initCheckNetwork()

      // 注册web-sdk连接断开通知
      this.initMeetingDisconnected()

      // 注册断网重连超时事件
      this.initMeetingTimeout()

      // 注册log
      this.initSDKLog()
    },


    /**
     * 注册全屏事件
     */
    initScreenfullEvent() {
      // 全屏改变事件
      screenfull.onchange(() => {
        this.$store.commit("meet/updateGlobalMeetState", {
          isFullModel: screenfull.isFullscreen
        })
      })
    },
    
    /**
     * 注册web-sdk 连接断开通知
     */
    initMeetingDisconnected() { 
      let _disconnectedEventCount = this.$i100MeetSDK.listenerCount('disconnected')
      if(_disconnectedEventCount == 0){
        this.$i100MeetSDK.on('disconnected', () => {
          loganLog(`disconnected 连接断开了---出现图标`)

          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: true
          })
          this.showToast(this.$t('meeting.netWorkBad'))
        })
      }

      let _websocketMayDisconnectedEventCount = this.$i100MeetSDK.listenerCount('websocketMayDisconnected')
      if(_websocketMayDisconnectedEventCount == 0){
        this.$i100MeetSDK.on('websocketMayDisconnected', () => {
          loganLog(`websocketMayDisconnected ping pong监测断网---出现图标`)
          //websocketMayDisconnected 后 ping一下
          this.ping()
          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: true
          })

          // this.showToast(this.$t('meeting.netWorkBad'))
        })
      }
      let _websocketStillAliveDisconnectedEventCount = this.$i100MeetSDK.listenerCount('websocketStillAlive')
      if(_websocketStillAliveDisconnectedEventCount == 0){
        this.$i100MeetSDK.on('websocketStillAlive', () => {
          loganLog(`websocketStillAlive ping pong 断网恢复---出现图标`)
          this.$store.commit("meet/updateGlobalMeetState", {
            isNetworkDisconnect: false
          })
          // 如果是自己发去的云共享同步一下状态
          if(this.isShareDoc){
            loganLog(`心跳恢复之后调用翻页同步`)
            this.$documentManager.resumePageJump()
          }
        })
      }
    },
    //ping
    ping () {
      var img = new Image();
      var start = new Date().getTime();
      var flag = false;
      var isCloseWifi = true;
      var hasFinish = false;
        
      img.onload = function () {
        if (!hasFinish) {
          flag = true;
          hasFinish = true;
          img.src = 'X:\\';
          loganLog(`Ping success--time:${new Date() - start}`)
        }
      };
      img.onerror = function (err) {
        if (!hasFinish) {
          console.error(222222,err)
          if (!isCloseWifi) {
            flag = true;
            img.src = 'X:\\';
            loganLog(`Ping error--time:${new Date() - start}`)
          } else {
            loganLog(`Ping error--time:${new Date() - start}`)
          }
          hasFinish = true;
        }
      };
      setTimeout(function () {
        isCloseWifi = false;
        loganLog(`Ping start`)
      }, 2);
      img.src = 'https://img0.baidu.com/it/u=1267308705,957501097&fm=253&fmt=auto&app=138&f=GIF?w=1265&h=492'
      setTimeout(function () {
        if (!flag) {
          hasFinish = true;
          img.src = 'X://';
          flag = false;
          loganLog(`Ping fail`)
        }
      }, 2000);
    },

    /**
     * 重新入会
     */
    async reJoinMeetHandle() {
      // 重新调用join
      const { conferenceNo, userName } = this.$configs

      try {
        const resData = await joinConference({
          conferenceNo,
          closeConference: true,
          userName
        })

        const { conference, roomid, userid } = resData

        setMeetInfo(
          resData["X-Conference-Token"],
          resData["X-Channel-Token"]
        )

        this.$router.replace({
          params: { userID: userid },
          query: {
            ...this.$route.query,
            roomID: roomid,
            conferenceNo: conference.conferenceNo
          }
        })

        window.location.reload()
      } catch (error) {
        this.logoutMeetHandle()
      }
    },

    /**
     * 离开会议
     */
    logoutMeetHandle() {
      this.networkTimeoutDialog = false

      this.$router.push({
        path: "/"
      })
    },


    /**
     * 注册web-sdk错误处理回调
     */
    initMeetingErrorEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('error')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on('error', error => {
        loganLog(`sdk抛出错误 error:${error,error.error.message}`)
        if(error && error.error && error.error.message && error.error.message.match(/can only be/)){
          this.edgePlayError = true
        }
        this.$sentry.captureException({
          msg: 'sdk抛出错误-----',
          userId: this.$configs.peerId,
          error
        })
      })
    },

    /**
     * 注册web-sdk会控通知回调
     */
    initMeetingControlEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('customCommand')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("customCommand", (data) => {
        console.log("----收到会控通知--------");

        try {
          data.metadata = JSON.parse(data.metadata);
        } catch (error) {
          data.metadata = {};
        }

        console.log(data);

        switch (data.command) {
          case CONTROL_COMMAND.HOST_JOIN_ROOM:
            this.handleHostJoinRoom(data);
            break;

          case CONTROL_COMMAND.AUTOMATICGRANT:
            this.handleAutomaticGrant(data);
            break;

          case CONTROL_COMMAND.CHANGE_NAME:
            this.handleChangeName(data);
            break;

          case CONTROL_COMMAND.GRANT:
            this.handleGrant(data);
            break;

          case CONTROL_COMMAND.TRANSFER_HOST:
            this.handleTransferHost(data);
            break;

          case CONTROL_COMMAND.RECOVER_HOST_PERMISSION:
            this.handleRecoverHostPermission(data);
            break;

          case CONTROL_COMMAND.SINGLE_MUTE:
            this.handleSingleMute(data);
            break;

          case CONTROL_COMMAND.SINGLE_UNMUTE:
            this.handleSingleUnMute(data);
            break;

          case CONTROL_COMMAND.SET_FOCUS: //收到焦点
            this.handleFocus(1,data);
            break;

          case CONTROL_COMMAND.CANCE_FOCUS: //收到取消焦点
            this.handleFocus(0,data);
            break;

          case CONTROL_COMMAND.OPEN_VIDEOS: //请求参会人开启视频
            this.handleOpenVideo(data);
            break;

          case CONTROL_COMMAND.INVITATION_UPLOADLOG: //请求参会人上传日志
            this.handleUploadLog(data);
            break;


          case CONTROL_COMMAND.START_CLOIDSHARE: //请求开启在线文档
            this.handleOpenDoc(data);
            break;
            

          case CONTROL_COMMAND.STOP_SHARE:
            this.handleStopShare(data);
            break;

          case CONTROL_COMMAND.SHIELD_VIDEOS:
            this.handleShieldVideos(data);
            break;


          case CONTROL_COMMAND.START_RECORD:
            this.handleRecord(data, 'isRecord', true);
            break;

          case CONTROL_COMMAND.STOP_RECORD:
            this.handleRecord(data, 'isRecord', false);
            break;

          case CONTROL_COMMAND.PAUSE_RECORD:
            this.handleRecord(data, 'recordPaused', true);
            break;

          case CONTROL_COMMAND.RESUME_RECORD:
            this.handleRecord(data, 'recordPaused', false);
            break;


          case CONTROL_COMMAND.REMOVE_SELF:
            this.handleRemoveSelf(data);
            break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.REMOVE_USER:
          //   this.handleRemoveUser(data);
          //   break;

          case CONTROL_COMMAND.RAISE_HAND:
            this.handleRaiseHand(data);
            break;

          case CONTROL_COMMAND.SINGLE_HAND_DOWN:
            this.handleDownHand(data);
            break;

          case CONTROL_COMMAND.ALL_HAND_DOWN:
            this.handleAllHandDown(data);
            break;

          /* ------------全局会议状态处理---------------- */
          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.END_MEET:
          //   this.handleEndMeet();
          //   break;

          // 不会发起了，改成了rtc的通知
          // case CONTROL_COMMAND.FORCE_END_MEET:
          //   this.handleForceEndMeet();
          //   break;

          case CONTROL_COMMAND.LOCK_CONFERENCE:
            this.handleLockConference(data);
            break;

          case CONTROL_COMMAND.UNLOCK_CONFERENCE:
            this.handleUnLockConference(data);
            break;

          case CONTROL_COMMAND.ALLOW_SELF_UNMUTE:
            this.handleAllowSelfUnmute(data);
            break;

          case CONTROL_COMMAND.FORBID_SELF_UNMUTE:
            this.handleForbidSelfUnmute(data);
            break;

          case CONTROL_COMMAND.OPEN_PLAY_TIPS:
            this.handleOpenPlayTipse(data);
            break;

          case CONTROL_COMMAND.CLOSE_PLAY_TIPS:
            this.handleClosePlayTipse(data);
            break;

          case CONTROL_COMMAND.OPEN_MUTE_JOIN_MEETING:
            this.handleOpenMuteJoinMeeting(data);
            break;

          case CONTROL_COMMAND.CLOSE_MUTE_JOIN_MEETING:
            this.handleCloseMuteJoinMeeting(data);
            break;

          case CONTROL_COMMAND.ALL_FORCE_MUTE:
            this.handleAllForceMute(data);
            break;

          case CONTROL_COMMAND.ALL_UNFORCE_MUTE:
            this.handleAllUnForceMute(data);
            break;

          case CONTROL_COMMAND.ALL_UNMUTE:
            this.handleAllUnMute(data);
            break;

          case CONTROL_COMMAND.SHARE_PERMISSIONS_ALL:
            this.handleSharePermissionsForAll(data);
            break;

          case CONTROL_COMMAND.SHARE_PERMISSIONS_HOST:
            this.handleSharePermissionsForHost(data);
            break;

          case CONTROL_COMMAND.RECORD_PERMISSIONS_ALL:
            this.handleRecordPermissionForAll(data);
            break;

          case CONTROL_COMMAND.RECORD_PERMISSIONS_HOST:
            this.handleRecordPermissionForHost(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_ALL:
            this.handleSchedulePermissionForALL(data);
            break;

          case CONTROL_COMMAND.SCHEDULE_PERMISSIONS_HOST:
            this.handleSchedulePermissionForHost(data);
            break;

          case CONTROL_COMMAND.MATURITY_NOTICE:
            this.handleMaturityNotice(data);
            break;


          case CONTROL_COMMAND.APPLY_HOST:
            this.handleApplyHost(data);
            break;

          case CONTROL_COMMAND.START_CLOUDRECORD: //开始云录制
            this.handleRecordFn(data,10);
            break;

          case CONTROL_COMMAND.START_BACK_CLOUDRECORD: //开始云录制准备好了
            this.handleRecordFn(data,11);
            break;
      
          case CONTROL_COMMAND.PAUSE_CLOUDRECORD: //暂停云录制
            this.handleRecordFn(data,20);
            break;
          case CONTROL_COMMAND.RESUME_CLOUDRECORD: //恢复云录制
            this.handleRecordFn(data,30);
            break;
          case CONTROL_COMMAND.RESUME_BACK_CLOUDRECORD: //恢复云录制准备好了
            this.handleRecordFn(data,31);
            break;
          case CONTROL_COMMAND.STOP_CLOUDRECORD: //结束云录制
            this.handleRecordFn(data,40);
            break;
          case CONTROL_COMMAND.INITIATE_QUESTIONNAIRE: //收到问卷
            this.handleInitiateQuestionnaireFn(data);
            break;
          default:
            console.log("unknow command");
        }
      })
    },

    /**
     * 注册web-sdk改名通知回调
     */
    initChangeNameEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('onDisplayNameChanged')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("onDisplayNameChanged", (data) => {
        console.log("-----rtc changeName------");
        console.log(data);

        const { peerId, displayName } = data;

        this.$store.commit("member/updateUser", {
          userId: peerId,
          userName: displayName,
        })
      })
    },

    /**
     * 注册web-sdk exitRoom 回调
     */
    initUserExitRoomEvent() {
      let _eventCount = this.$i100MeetSDK.listenerCount('onExitMeeting')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("onExitMeeting", (data) => {
        console.log("-----rtc exitRoom------");
        console.log(data);

        // 1：被服务器踢出当前房间
        // 2: 主持人主动结束当前会议EndConference
        // 3: 服务器强制结束当前会议forceEndConference
        const { reason } = data

        const textConfig = {
          1: this.$t('meeting.getOut'),
          2: this.$t('meeting.overTimeMeet'),
          3: this.$t('meeting.closeMeet'),
        }

        // 不会出现弹窗
        if (!(reason === 2 && this.isHost)) { // 主持人已结束会议，并且自己是主持人
          this.$store.commit("meet/updateGlobalMeetState", { //返回上一页弹框提示提示
            isForceEndMeetToast: reason,
          });
        }

        this.isNeedDisconnect = false // 不需要触发destory中的断开逻辑
        // 执行sdk退房操作
        // this.$meetingControl.logoutRoom(false); // 不需要走leave
        
        this.$router.push({
          path: "/",
        })

      })
    },


    /**
     * 注册web-sdk 连接超时 回调
     */
    initMeetingTimeout() { 
      this.$i100MeetSDK.on('connectionTimeOut', () => {
        // console.log('超时了---------------------222')
        loganLog(`connectionTimeOut 超时出现弹窗`)
        this.networkTimeoutDialog = true
      })
    },


    /**
     * 注册web-sdk log 回调
     */
    initSDKLog() {
      this.$i100MeetSDK.on('sdkLogReport', (message) => {
        //先注释掉 errormsg会打印
        if(message != '[web sdk] errorMsg: code -- 80001005' && message != '[web sdk] errorMsg: code -- 80000518'){
          loganLog(`sdk日志-${message}`)
        }
      })
    },

    /**
     * 注册web-sdk 网络检测
     */
    initCheckNetwork() { 
      let _eventCount = this.$i100MeetSDK.listenerCount('networkQuality')
      if(_eventCount>0){ //如果注册过了就返回 避免多次注册   重复的问题以后再查一下
        return
      }
      this.$i100MeetSDK.on("networkQuality", (qualityInfo) => {
        switch (qualityInfo.uplink) {
          case QRTCQuality.QRTCQuality_Excellent:

            // console.error('最好')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Excellent,
              text: this.$t('meeting.best')
            }

            break;
          case QRTCQuality.QRTCQuality_Good:

            // console.error('好')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Good,
              text: this.$t('meeting.good')
            }

            break;
          case QRTCQuality.QRTCQuality_Poor:

            console.error('一般')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Poor,
              text: this.$t('meeting.commonly')
            }

            break;
          case QRTCQuality.QRTCQuality_Bad:

            console.error('差')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Bad,
              text: this.$t('meeting.noGood')
            }

            break;
          case QRTCQuality.QRTCQuality_Vbad:

            console.error('很差')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Vbad,
              text: this.$t('meeting.noBest')
            }

            break;
          case QRTCQuality.QRTCQuality_Down:

            console.error('不可用')

            this.networkInfo = {
              ...qualityInfo,
              value: QRTCQuality.QRTCQuality_Down,
              text: this.$t('meeting.notAvailable')
            }

            break;
        }
      })
    },

    /**
     * 收到改名
     */
    async handleChangeName(data) {
      const selfId = this.$configs.peerId;
      const { userName } = data.metadata;

      if (selfId === data.to.peerid) {
        // 要更改名字的为当前用户
        // 调用RTC改名
        this.$i100MeetSDK.i100MeetingControl.changeLocalDisplayName(userName);

        // im改名
        this.$i100MeetSDK.i100IM.updateNickAndAvatar({
          nickname: userName,
        });
        
        this.$configs.userName = userName
      }

      if(this.isShareDoc){
        const fileData = await queryDocumentShareData({
            roomid: this.$configs.roomId
        })
        console.error('文档改名')
        if (!fileData) return
        console.error('文档改名',fileData)
        documentDisplayName({ displayName: userName ,userId: selfId,id: fileData.id})
      }
    },

    /**
     * 在userList中获取主持人信息
     */
    setOldHostToUser() {
      // 将之前的老主持人重置为普通用户
      const oldHost = this.$store.state.member.userList.find((i) => i.roleCode === ROLE_CODE.HOST) || {};
      // console.error('老主持人',oldHost,JSON.stringify(this.userList),this.$store.state.member.userList)
      if (oldHost.userId) {
        this.$store.commit("member/updateUser", {
          userId: oldHost.userId,
          roleCode: ROLE_CODE.USER,
        });
      }
    },

    /**
     * 主持人进入房间
     */
    handleHostJoinRoom(data) {
      const { peerid } = data.to;

      // let isRun = true

      // if (peerid === this.$configs.peerId) { // 本人
      //   if (this.selfInfo.userId) { // 存在，那说明时序没有问题
      //     console.error('时序没有问题---------')
      //   } else {
      //     window._localRoleCode = ROLE_CODE.HOST
      //     isRun = false
      //     console.error('时序错误---------')
      //   }
      // }

      // if (isRun) {
      //   this.$store.commit("member/updateUser", {
      //     userId: peerid,
      //     roleCode: ROLE_CODE.HOST,
      //   })
      // }

      console.error('---------更新成主持人---------');

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })
    },

    /**
     * 自动选取主持人
     */
    async handleAutomaticGrant(data) {
      console.log("------automaticGrant-------");
      console.log(data);

      const { peerid } = data.to;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST,
        })
      // })
    },

    /**
     * 授权角色变更
     */
    handleGrant(data) {
      console.log("--handleGrant---");
      console.log(data);

      const selfId = this.$configs.peerId;
      const { peerid } = data.to;
      const { roleCode } = data.metadata;

      if (peerid === selfId) { // 角色变更的是自己
        if (Number(roleCode) === ROLE_CODE.CO_HOST) {
          this.showToast(this.$t('meeting.becomeGoHost'))
        } else if (Number(roleCode) === ROLE_CODE.USER) {
          if (this.isCoHost) { // 当前为联席主持人，角色变更为普通用户
            this.showToast(this.$t('meeting.backModerator'))
          }
        }
      }

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: Number(roleCode)
        })
      // })
    },

    /**
     * 转移主持人
     */
    handleTransferHost(data) {
      console.log("--handleTransferHost---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const selfId = this.$configs.peerId;
      if (selfId === data.to.peerid) {
        const fromUserInfo = this.userList.find((i) => i.userId === data.from.peerid)

        this.commonTipText = `${strMiddleEllipsis(fromUserInfo.userName, 10, 3, 2)}` + this.$t('meeting.giveYou');
        this.commonTipDialog = true;
      }

      // this._handleAddUser(data.to.peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: data.to.peerid,
          roleCode: ROLE_CODE.HOST,
        });
      // })
    },

    /**
     * 申请成为主持人
     */
    handleApplyHost(data) {
      console.log("--handleApplyHost---");
      console.log(data);

      // this._handleAddUser(data.from.peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: data.from.peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })

      if (data.from.peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.becomeMain'))
      }
    },

  /**
   * 云录制
   */
  handleRecordFn(data,type) {
    loganLog(`--收到云录制信令---type:${type}`);
    if(type === 10){//开始录制
      if (this.isHost || this.isCoHost) { 
        // if(this.$store.state.meet.ownerId === this.$configs.peerId){
        //   this.commonTipText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
        // } else {
        //   this.commonTipText = '会议结束后，从会议负责人和协办人获取录制文件链接'
        // }
        this.intervalTip = '云录制中'
        this.intervalText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
        this.intervalDialog = true;
        this.removeTime = 5
        clearInterval(this.intervalTimer)
        // 开启定时器
        this.intervalTimer = setInterval(() => {
          if (this.removeTime === 0) {
            this.intervalDialog = false;
            clearInterval(this.intervalTimer)
            console.error('倒计时结束----')
          } else {
            this.removeTime = this.removeTime - 1;
          }
        }, 1000);
      }
    } else if(type === 40){ //结束录制
      if (this.isHost || this.isCoHost) { 
        this.intervalTip = '云录制已结束'
        this.intervalText = '录制结束后，可在相关业务详情页查看云端录制视频文件'
        this.intervalDialog = true;
        this.removeTime = 5
        clearInterval(this.intervalTimer)
        // 开启定时器
        this.intervalTimer = setInterval(() => {
          if (this.removeTime === 0) {
            this.intervalDialog = false;
            clearInterval(this.intervalTimer)
            console.error('倒计时结束----')
          } else {
            this.removeTime = this.removeTime - 1;
          }
        }, 1000);
      }
    }
      
    this.$store.commit("meet/updateGlobalMeetState", {
      cloudRecorState: type
    })
  },


    /**
     * 问卷
     */
     handleInitiateQuestionnaireFn(data){
      const selfId = this.$configs.peerId;
      let targets = data.targets
      console.log("--handleInitiateQuestionnaireFn---",selfId,data.from.peerid,data);
      targets && targets.forEach(item=>{
        if(item == selfId){
          // console.error(1111,this.isExitUserShare, this.isOpenWenjuan, data.from.peerid == selfId)
          if(this.isExitUserShare && this.isExitUserShare.userId == selfId || this.isOpenWenjuan || data.from.peerid == selfId){//在共享中、问卷打开中、发送人是自己
            this.isShowWenjuanTips = true
          } else {
            this.isOpenWenjuanAsk = true
          }
        }
      })
    },

    /**
     * 收回主持人权限
     */
    handleRecoverHostPermission(data) {
      console.log("--handleRecoverHostPermission---");
      console.log(data);

      // 将之前的老主持人重置为普通用户
      this.setOldHostToUser();

      const { peerid } = data.from; // 发起收回主持人的peerId

      console.log(peerid);

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          roleCode: ROLE_CODE.HOST
        })
      // })
    },

    /**
     * 收到静音操作
     */
    async handleSingleMute(data) {
      console.log("--handleSingleMute---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {

        try {
          // 自己收到主持人的静音操作
          await this.$meetingControl.muteLocalAudio(true); // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          })
          this.$store.commit("meet/updateGlobalMeetState", {
            isUseHuaTong: false,
          })

          this.showToast(this.$t('meeting.mainNoVoice'))
        } catch (error) {
          this.$sentry.captureException({
            msg: '收到会控的静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          }) 
        }
      }
    },


    // 收到上传日志通知
    async handleUploadLog(data){
      console.error('收到上传日志通知')
      const { roomId, peerId, userId } = this.$configs
      if(peerId === data.to.peerid){
        const currentTimestamp = new Date().getTime()
        const endDate = dayjs(currentTimestamp).format('YYYY-MM-DD')
        const startDate = dayjs(currentTimestamp).subtract(1, 'day').format('YYYY-MM-DD')
        try{
          let uniqueId = Math.floor(Math.random() * 1000000000) //唯一哈希值 同批次日志一个ID
          let res =  await Logan.report({
            reportUrl: reportLogUrl,
            deviceId: "web_" + getUuid(),
            fromDayString: startDate,
            toDayString: endDate,
            customInfo: JSON.stringify({
              peerId: peerId,
              roomId: roomId,
              userId: userId,
              uniqueId: uniqueId
            }),
            webSource: 'ybmeet'
          })
        }catch(error){
          console.error('上传日志失败',error)
        }
      }
     

    },

    //收到打开文档列表
    async handleOpenDoc(data){
      console.error('收到打开文档列表通知')
      const { roomId, peerId, userId } = this.$configs
      if(peerId === data.to.peerid){
        this.$confirm('主持人请你开启在线文档?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          console.error('打开文档列表')
          this.isShowDocumentPanel = true
        }).catch(e=>console.error('打开文档列表失败',e))
        console.error('收到自己打开文档列表通知')
      }
    },

   
    /**
     * 收到焦点事件
     */
    async handleFocus(type,data) {
      console.log("--handleFocus---");
      console.log(type,data);
      let _id = type == 0 ? 0 : data.to.peerid
      this.$store.commit("meet/updateGlobalMeetState", {
        isFocusScreen: _id
      })
      
      let _userlist = this.$store.state.member.userList
      if (type == 0 && _userlist.find((i) => i.isTopShare) && !_userlist.find((i) => i.isDbClick)) { // 存在共享画面且没有锁定画面
        window._isClickTopShare = true
        this.$store.commit('member/removeShareView')
      }
      
      try {
        await this.$meetingControl.handleFocusScreen(_id);
      } catch (error) {
        loganLog(`开启焦点画面失败---_id:${_id}---error${error}`)
      }
      
    },

    /**
     * 取消静音操作，需要弹出对话框让用户去选择
     */
    handleSingleUnMute(data) {
      console.log("--handleSingleUnMute---");
      console.log(data);

      const selfId = this.$configs.peerId;
      console.log(selfId);
      console.log(selfId === data.to.peerid);

      if (selfId === data.to.peerid) {
        // 自己收到主持人的取消静音操作
        this.unMuteAudioDialog = true;
      }
    },

    /**
     * 用户通过弹窗主动解除静音
     */
    async resumeAudioDialogHandle() {
      const selfId = this.$configs.peerId;

      try {
        await this.$meetingControl.muteLocalAudio(false);

        this.$store.commit("member/updateUser", {
          userId: selfId,
          isUseHuaTong: true,
        });
        this.$store.commit("meet/updateGlobalMeetState", {
          isUseHuaTong: true,
        })

        this.unMuteAudioDialog = false;
      } catch (error) {
        this.$sentry.captureException({
          msg: '用户通过弹窗主动解除静音',
          userId: selfId,
          userName: this.selfInfo.userName,
          error
        })
      }
    },

    /**
     * 停止共享
     */
    async handleStopShare(data) {
      console.log("--handleStopShare---");
      console.log(data);

      const selfId = this.$configs.peerId;
      let to_userid = data.to.peerid
      
      if(to_userid.indexOf('cloudshare') > -1) {
        const fileData = await queryDocumentShareData({
            roomid: this.$route.query.roomID
        })
        to_userid = fileData.roomId + '_' + fileData.userId
      }

      if (selfId === to_userid) { // 本人收到主持人的关闭分享操作
        // 执行后会触发分享结束的回调
        // console.error(22222,this.isShareDoc)
        if(this.isShareDoc){
          this.$documentManager.docShareStopFn(this.$configs.roomId,this.$configs.userId)
        } else {
          this.$meetingControl.stopShare()
        }

        if (selfId === data.from.peerid) { // 自己操作自己
          console.error('自己操作自己')
          return
        } else {
          this.commonTipText = this.$t('meeting.mainStopShare')
          this.commonTipDialog = true
        }
      } 

    },

    /**
     * 收到关闭视频的会控指令
     */
    handleShieldVideos(data) {
      console.log("--handleShieldVideos---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 自己收到主持人的关闭视频操作

        this.$meetingControl.stopLocalPreview(); // 主动调用信令，执行关闭视频操作

        this.$store.commit("member/updateUser", {
          userId: selfId,
          isUseShiPin: false,
        });
        this.$store.commit("meet/updateGlobalMeetState", {
          isUseShiPin:false,
        })

        //如果预览了美颜画面需要重启
        if(this.$store.state.isShowSettingPanel && [2,3,4].includes(this.$store.state.settingPanelDefaultIndex)){
          this.$refs.meetingSettings.refreshLocalPreviewOnly();//重置预览画面
        }

        //this.commonTipText = this.$t('meeting.closedVideo');
        //this.commonTipDialog = true;
        this.$totast(this.$t('meeting.closedVideo'))
      }
    },
  // 收到开启视频请求
    async handleOpenVideo(data){
      const selfId = this.$configs.peerId;
      if(selfId === data.to.peerid){

        this.$confirm('', {
          title:'请求打开您的摄像头',
          confirmButtonText: '同意',
          cancelButtonText:'不同意',
          center: true
        }).then(() => {
            eventBus.$emit('start-local-video');
            eventBus.$emit('create-audio-transport')
        
        }).catch(()=>{
          console.error('不同意')

        })
      }
    },



    /**
     * 录制相关
     */
    async handleRecord(data, key, value) {
      console.log("--record---");
      console.log(data);

      const { peerid } = data.from
      //如果是
      if(data.command == "stopRecord"){ //如果stop的时候要把暂停状态清除
        this.$store.commit("member/updateUser", {
          userId: peerid,
          recordPaused: false
        })
      }

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          [key]: value
        })
      // })
    },


    /**
     * 收到踢出自己命令
     */
    async handleRemoveSelf(data) {
      console.log("--handleRemoveSelf---");
      console.log(data);

      const selfId = this.$configs.peerId;

      if (selfId === data.to.peerid) {
        // 已经在其他设备上登录
        this.exitMeetByTimer(this.$t('meeting.noMembership'));
      }
    },


    /**
     * 举手
     */
    async handleRaiseHand(data) {
      console.log("--handleRaiseHand---");
      console.log(data)

      const { peerid } = data.from;

      // this._handleAddUser(peerid, () => {
        this.$store.commit("member/updateUser", {
          userId: peerid,
          isRaiseHand: true,
        })
      // })
    },

    /**
     * 手放下
     */
    async handleDownHand(data) {
      console.log("--handleDownHand---");
      console.log(data);

      const { peerid } = data.to;
      
      this.$store.commit("member/updateUser", {
        userId: peerid,
        isRaiseHand: false,
      });
    },

    /**
     * 所有手放下
     */
    async handleAllHandDown(data) {
      console.log("--handleAllHandDown---");
      console.log(data);
      this.userList.forEach((item) => {
        const { userId } = item;

        this.$store.commit("member/updateUser", {
          userId,
          isRaiseHand: false,
        });
      });
    },

    /* -------------全局会议状态变更------------------ */

    /**
     * 免费时长倒计时提示
     */
    async handleMaturityNotice(data) {
      // console.log("--handleMaturityNotice---");
      // console.log(data)

      const { remainingTime } = data.metadata

      if (!this.maturityTimeStr) { // 是主持人，并且maturityTimeStr不存在，代表还没有提示过，此时发起提示

        if (this.isHost) { // 如果是主持人，才发起弹窗提示
          this.commonTipText = this.$t('meeting.lijiEnd') + `${remainingTime}` + this.$t('meeting.inMinutes');
          this.commonTipDialog = true
        }

        // 开启定时器
        this.maturityTimer = countDownTimer(
          remainingTime,
          (min, sec) => {
            this.maturityTimeStr = `${min}:${sec}`
          },
          () => {
            this.maturityTimeStr = ''
          }
        )
      }
    },


    /**
     * 锁定会议
     */
    handleLockConference(data) {
      console.error("锁定--")
      console.log(data)

      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 1
      })

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.lockMeeting'))
      }
    },

    /**
     * 解锁会议
     */
    handleUnLockConference(data) {
      console.log("解锁--");
      this.$store.commit("meet/updateGlobalMeetState", {
        lockedState: 0,
      });

      const { peerid } = data.from
      if (peerid === this.$configs.peerId) {
        this.showToast(this.$t('meeting.okMeet'))
      }
    },

    /**
     * 允许成员自我解除静音
     */
    handleAllowSelfUnmute(data) {
      console.log("---handleAllowSelfUnmute--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 1,
      });
    },

    /**
     * 禁止成员自我解除静音
     */
    handleForbidSelfUnmute(data) {
      console.log("--handleForbidSelfUnmute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allowSelfUnmute: 0,
      });
    },

    /**
     * 成员加入会议时开启播放提示音
     */
    handleOpenPlayTipse(data) {
      console.log("--handleOpenPlayTipse---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 1,
      });
    },

    /**
     * 成员加入会议时关闭播放提示音
     */
    handleClosePlayTipse(data) {
      console.log("---handleClosePlayTipse--");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        playTips: 0,
      });
    },

    /**
     * 成员加入会议开启静音
     */
    handleOpenMuteJoinMeeting(data) {
      console.log("--handleOpenMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 1,
      });
    },

    /**
     * 成员加入会议关闭静音
     */
    handleCloseMuteJoinMeeting(data) {
      console.log("--handleCloseMuteJoinMeeting---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        muteJoinMeeting: 0,
      });
    },

    /**
     * 全体静音操作
     */
    async _allMuteHandle(data, allowSelfUnmute) {
      // 全体静音：
      // 1、主持人操作，生效范围：在会场/新入会的——所有联席主持人（包括owner）+所有普通成员（包括owner）
      // 2、联席主持人操作，生效范围：在会场/新入会的——其他联席主持人（包括owner）+所有普通成员（包括owner）

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 1,
        allowSelfUnmute
      });

      const selfId = this.$configs.peerId;
      let isRun = false

      if (this.isHost) { // 自己是主持人
        console.log("主持人不会被静音");
      } else if (this.isCoHost) { // 自己是联席
        if (selfId !== data.from.peerid) {
          // 操作人不是自己
          console.log("是联席，并且操作人不是是自己");
          isRun = true
        } else {
          console.log("是联席，并且操作人是自己");
        }
      } else { // 普通用户
        console.log("普通用户");
        isRun = true
      }

      if (isRun && this.selfInfo.isUseHuaTong) { // isRun为true并且非静音状态
        try {
          await this.$meetingControl.muteLocalAudio(true); // 主动调用信令，执行静音操作

          this.$store.commit("member/updateUser", {
            userId: selfId,
            isUseHuaTong: false,
          });
          this.$store.commit("meet/updateGlobalMeetState", {
            isUseHuaTong: false,
          })

          this.showToast(this.$t('meeting.allNoVoice'))
        } catch (error) {
          this.$sentry.captureException({
            msg: '全体静音操作',
            userId: selfId,
            userName: this.selfInfo.userName,
            error
          })
        }
      }
    },

    /**
     * 全体强制静音
     *
     */
    handleAllForceMute(data) {
      console.log("--handleAllForceMute---");
      console.log(data);

      this._allMuteHandle(data, 0);
    },

    /**
     * 全体非强制静音
     */
    handleAllUnForceMute(data) {
      console.log("--handleAllUnForceMute---");
      console.log(data);

      this._allMuteHandle(data, 1);
    },

    /**
     * 全体解除静音
     * // 全局的静音状态  0：全体解除静音  1：全体静音
     */
    handleAllUnMute(data) {
      console.log("--handleAllUnMute---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        allMuteState: 0,
      });

      // 主持人操作，对所有的联席和普通成员生效
      // 联席主持人操作，对其他联席和普通成员生效
      if (data.from.peerid === this.selfInfo.userId) { // 操作人为自己，此时不做任何操作
        return
      } else { // 操作其他人
        if (this.isHost) { // 如果自己是主持人，则不做任何操作
          return
        } else {
          if (!this.selfInfo.isUseHuaTong) {
            this.unMuteAudioDialog = true;
          }
        }
      }

      // if (!this.isHost) {
      //   // 如果自己为主持人，则不处理
      //   if (!this.selfInfo.isUseHuaTong) {
      //     this.unMuteAudioDialog = true;
      //   }
      // }
    },

    /**
     *
     */
    handleSharePermissionsForAll(data) {
      console.log("--handleSharePermissionsForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 0,
      });
    },

    /**
     *
     */
    handleSharePermissionsForHost(data) {
      console.log("--handleSharePermissionsForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        sharePermission: 1,
      });
    },

    /**
     *
     */
    handleRecordPermissionForAll(data) {
      console.log("--handleRecordPermissionForAll---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 0,
      });
    },

    /**
     *
     */
    handleRecordPermissionForHost(data) {
      console.log("--handleRecordPermissionForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        recordPermission: 1,
      });
    },

    /**
     *
     */
    handleSchedulePermissionForALL(data) {
      console.log("--handleSchedulePermissionForALL---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        agendaPermission: 0,
      });
    },

    /**
     *
     */
    handleSchedulePermissionForHost(data) {
      console.log("--handleSchedulePermissionForHost---");
      console.log(data);

      this.$store.commit("meet/updateGlobalMeetState", {
        agendaPermission: 1,
      });
    },

    /**
     * 点击知道了，立刻退出会议
     */
    shortlyExitMeet() {
      clearInterval(this.removeTimer);
      this.isLockExitRoom = false
      // 返回会议面板

      this.$router.push({
        path: "/",
      });
    },

    exitMeetByTimer(exitMeetText) {
      if (this.isLockExitRoom) {
        console.error('exitMeetByTimer锁定中, 直接返回')
        return
      }
      console.error('正常执行----')
      this.isLockExitRoom = true

      this.isNeedDisconnect = false // 不需要触发destory中的断开逻辑

      this.exitMeetText = exitMeetText;
      this.exitMeetDialog = true;

      // 执行sdk退房操作
      this.$meetingControl.logoutRoom(false); // 不需要走leave

      clearInterval(this.removeTimer)

      // 开启定时器
      this.removeTimer = setInterval(() => {
        if (this.removeTime === 0) {
          console.error('倒计时结束----')
          this.shortlyExitMeet();
        } else {
          this.removeTime = this.removeTime - 1;
        }
      }, 1000);
    },

    // 主持人结束会议
    hostEndMeeting: throttle(async function() {
      try {
        // 调用会控结束会议
        await fetchMeetControl({
          command: CONTROL_COMMAND.END_MEET,
        }, this.$configs)
      } catch (error) {
        // this.$refs.meetingLogoutBtn.resetSenEndMeeting();//清除防抖锁
        loganLog(`结束会议失败-----error:${JSON.stringify(error)}`)
      }
    }, 3000, {
      trailing: false
    }),

    // 所有角色退出会议
    logoutMeeting() {
      this.$router.push({
        name: 'index'
      });
    },

    restMediaSrc() {
      // console.error('restMediaSrc-111--')

      const resetList = document.querySelectorAll('.media-reset-flag')

      for (const el of resetList) {
        el.srcObject = null
        el.src = ''
      }
    },

    //倒计时基数
    handleCount(minutes, seconds, type) {
      this.countDownTipMinutes = minutes;
      this.countDownTipSeconds = seconds;
      this.countDownTipType = type;
    },
    // 关闭倒计时tip框
    closeTip() {
      let that = this;
      that.$refs.meetingCountdownBtn.close();
    },
    // 重置倒计时框
    resetTip() {
      let that = this;
      that.$refs.meetingCountdownBtn.reset();
    },

    showToast(text) {
      clearTimeout(this.toastTimer)
      this.toastText = text
      this.isShowToast = true

      this.toastTimer = setTimeout(() => {
        this.isShowToast = false
      }, 3000)
    },

    // 显示设置面板
    openSetting(index) {
       this.$store.commit("isShowSettingPanel", true);
       this.$store.commit("settingPanelDefaultIndex", index);
    },

    clearMenuBar() {
      clearTimeout(this.menuBarTimer)
    },
    menuBarLeaveHandler(){
      this.isShowMenuBar = false
    },
   
    menuBarMoveHandler: throttle(function() {
      this.isShowMenuBar = true
      // 清理掉之前的定时器
      clearTimeout(this.menuBarTimer)

      this.menuBarTimer = setTimeout(() => {
        this.isShowMenuBar = false

        clearTimeout(this.menuBarTimer)
      }, 3000)
    }, 400, {
      trailing: false
    }),

    overMenuBarHandle: throttle(function() {
      if(!this.isFullModel){

        this.isShowMenuBar = true
        // 清理掉之前的定时器
        clearTimeout(this.menuBarTimer)
        
      }

    }, 50, {
      trailing: false
    }),

    iframeBeforeClosed(e) {
      this.isOK = false
      this.webviewUrl = ""
      this.questionnaireUrl = ""

      this.iframeLoading = true

      const el = document.querySelector('.el-dialog-iframe')

      if (el) {
        el.style.top = 'initial'
        el.style.left = 'initial'
      }
    },
    async iframeOpen() {
      if(this.$store.state.meet.isNetworkDisconnect){
        this.showToast(this.$t('meeting.netWorkBad'))
        return
      }

      const routeQuery = this.$route.query
      let meetingId = routeQuery.conferenceId

      let status = this.selfInfo.roleCode
      let userId = this.$configs.userId || ""
      if (this.isOK) {
        this.iframeBeforeClosed()
        return
      }

      try {        
        const data = await fetchConference({
          id: meetingId
        })

        this.agendaId = data.agendaId || "";
        this.agendaPermission = data.agendaPermission;
        let language = getAcceptLanguage() === 'zh-CN' ? 'zh' : 'en'
        if (this.agendaId) {
          this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + this.agendaId + "&status=" + status + "&userId=" + userId +  "&langue=" + language + "&jurisdiction=" + this.agendaPermission;
          this.isOK = true;
        } else {

          try {
            const agendaId = await fetchConferenceSnowflake()

            this.webviewUrl = meetAgendasUrl + "/h5/agendas?meetingId=" + meetingId + "&agendaId=" + agendaId + "&status=" + status + "&userId=" + userId +  "&langue=" + language +"&jurisdiction=" + this.agendaPermission;
            this.isOK = true;

          } catch (error) {
            console.log(error) // TODO:
          }
        }
      } catch (error) {
        console.log(error) // TODO:
      }
    },
    wenjuanIframeOpen(){
      if(this.questionnaireUrlStr){
        this.isOpenWenjuan = true
        this.questionnaireUrl = `${this.questionnaireUrlStr}&roleCode=${this.selfInfo.roleCode}`
        this.isShowWenjuanTips = false
      }else{
        this.showToast('请稍后再试')
      }
    },
    iframeLoad() {
      this.iframeLoading = false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      loganLog(`${from.name}, 'from'`)

      //  这里的vm指的就是vue实例，可以用来当做this使用
      vm.preRouterName = from.name  //获取上一级路由的name
    })
  },
  async beforeRouteLeave(to, from, next) {
    console.error('即将beforeRouteLeave')

    console.log(to)

    this.$store.commit("reset")

    this.restMediaSrc()
    if(this.$configs.businessType == 10007){
      try {
        const resData = await getHostRedirect(this.$route.query.conferenceId, this.$configs.userId,)
        console.error('getHostRedirect接口请求',resData)

        await this.$meetingControl && this.$meetingControl.logoutRoom(this.isNeedDisconnect)
        if(resData && resData.roleCode && resData.roleCode==20){
          window.location.replace(this.$store.state.meet.hostRedirectUrl)
        }else{
          window.location.replace(this.$store.state.meet.RedirectUrl)
        }
      } catch (error) {
        loganLog(`getHostRedirect接口请求失败-----error:${JSON.stringify(error)}`)
        if(this.isHost && this.$store.state.meet.hostRedirectUrl){
            window.location.replace(this.$store.state.meet.hostRedirectUrl)
        }else if(this.$store.state.meet.RedirectUrl){
          window.location.replace(this.$store.state.meet.RedirectUrl)
        } else {
        next()
      }
      }
    } else if(this.$configs.businessType == 10008){
      await this.$meetingControl && this.$meetingControl.logoutRoom(this.isNeedDisconnect)
      window.location.replace(this.mopanRedirectUrl)
    } else{
      next()
    }
  },

  destroyed() {
    this.$notify.closeAll()
    this.removeEnterHandle()
    this.waitLoading && this.waitLoading.close()
    // 清空storage event
    !isSafari() && window.removeEventListener('storage', this.handleStorageEvent)

    // 清空倒计时
    clearInterval(this.removeTimer)
    clearTimeout(this.toastTimer)
    clearInterval(this.maturityTimer)
    clearInterval(this.shenceDataTimer)
    clearTimeout(this.getAudioLevelsTimer)
    

    //清理resize
    window.removeEventListener('resize', this.calculateWidth)
    window.removeEventListener('visibilitychange', this.calculateWidth)

    //清理nosleep
    this.noSleep.disable();

    // 清理鼠标移动相关
    this.clearMenuBar()

    setTimeout(() => {
      // console.error("55552 isNeedDisconnect",this.isNeedDisconnect)
      if (this.isNeedDisconnect) { // 需要断开
        // 调用rtc退房命令
        this.$meetingControl && this.$meetingControl.logoutRoom(true) // 需要走leave
      }
    }, 100);

    if (this.isFullModel) {
      screenfull.exit()
    }

    // 清空vuex中member, reset状态
    this.$store.commit("member/reset");
    this.$store.commit("meet/reset");
    this.$store.commit('resetMessageList')

    // reset $configs
    this.$configs.userId = ''
    this.$configs.roomId = ''
    this.$configs.userName = ''
    this.$configs.avatarUrl = ''
    this.$configs.peerId = ''
    this.$configs.conferenceNo = ''
    this.$configs.attendMap = {}
  }
}
</script>

<style lang="scss" scoped>
  @mixin pub_style($posi,$left,$right,$index){
    position: $posi;
    left: $left;
    right: $right;
    z-index: $index;
  }
  @mixin base_style($width){
    width: $width;
    height: $width;
  }
  @mixin dis_style($dis,$jus,$align){
    display: $dis;
    justify-content: $jus;
    align-items: $align;
  }
  @mixin ali_style($dis,$ali){
    align-items: $ali;
    display: $dis;
  }
  @mixin top_style($posi,$left,$top,$radius,$padding,$bag){
  position: $posi;
  left: $left;
  top: $top;
  border-radius: $radius;
  padding: $padding;
  background: $bag;
}

.uploadProgres{
  .upload-progress-tips{
    margin-bottom: 16px;
  }

  .el-progress-bar__outer{
    height: 10px !important;
  }
}
.doc-fire-dialog {
  .title {
      height: 24px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
      line-height: 24px;
      margin-bottom: 12px;
  }

  .text {
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
  }

  .bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;

      .btn {
          width: 88px;
          height: 32px;
          background: #FFFFFF;
          border-radius: 16px;
          border: 1px solid #E5E5E5;
          text-align: center;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: -5px;
          line-height: 32px;
          cursor: pointer;

          &:hover {
              border-color: #1AB370;
              color: #1AB370;
          }
      }
  }
}

.meeting {
  position: relative;

  .main {
    background: #000000;
    overflow: hidden;
    height: 100%;
  }

  .top {
    @include pub_style(absolute,0,0,11);
    top: 0;
    display: block;
    justify-content: space-between;
    padding: 0 16px 0 40px;
    height: 0;
    .left {
      @include  ali_style(flex,center);
      // pointer-events: all;
      float: left;
      padding-top: 18px;
      .title {
        // width: 80px;
        height: 28px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1ab370;
        line-height: 28px;
        text-shadow: 0px 20px 50px rgba(0, 0, 0, 0.3);
        // margin-left: 40px;
        margin-right: 24px;
      }
    }

    .right {
      float: right;
      padding-top: 18px;
      @include  ali_style(flex,center);
      // height: 60px;
      // pointer-events: all;
      // padding-right: 16px;
    }

    .btnItem {
      margin-left: 10px;
      margin-top: 5px;
    }
  }

  .optionWrap {
    @include pub_style(fixed,0,0,999);
    bottom: 0;
    height: 72px;
    background: #f2f2f2;
    border-radius: 4px 4px 0px 0px;
    @include dis_style(flex,space-between,center);
    padding: 0 16px;
    z-index: 999;
    .left-option {
      @include  ali_style(flex,center);
    }

    .middle-section{
      @include ali_style(flex,center);
      // justify-content: center;
      .otherOption {
        display:flex;
        background: #fff;
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);

        overflow: hidden;
        border-radius: 24px;
        margin-left: 14px;
        min-width: 106px;
        .agenda-box {
          @include dis_style(flex,space-between,center);
          height: 48px;
          overflow: hidden;
          cursor: pointer;
          padding:0 10px;
          &:hover {
            background: rgba(0, 0, 0, 0.05);
          }
          .agendaInfo {
            font-size: 14px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            margin-left:6px;
          }
      }
      }
    }
  }
}
.disconnect-box {
  z-index: 9999999;
  @include top_style(fixed,50%,20px,24px,0 12px,#FFFFFF);
  transform: translateX(-50%);
  height: 48px;
  box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.1);
  @include ali_style(flex,center);
  .icon {
    @include base_style(24px);
    background: url(~@/assets/images/agendas_loading_48.gif) no-repeat;
    background-size: 100% 100%;
    margin-right: 10px;
  }

  .text {
    color: #000000;
    font-size: 14px;
  }
}

// 免费倒计时
.freeCoutTime {
  line-height: 24px;
  height: 24px;
  padding: 0 4px;
  box-shadow: 0px 20px 50px 0px rgb(0 0 0 / 30%);
  border-radius: 2px;
  background: rgba(51, 51, 51, 0.4);
  // margin: 16px 0 0 30px;
  margin-right: 10px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.85);
}
.user-totast {
  @include top_style(fixed,50%,50%,10px,20px 40px,#333333);
  z-index: 99999;
  transform: translate(-50%, -50%);
  font-size: 16px;
  color: #fff;
}

.watermarkBox{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  pointer-events: none; /* 关键点：水印不响应鼠标事件 */
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none; /* 防止文字被选中 */
  color: rgba(102,102,102,0.6);
  font-size: 20px;
  font-weight: 500;
  transform: rotate(-45deg);
}

.iframe-box {
  @include dis_style(flex,center,center);
  position: relative;
  // height: 530px;
  .iframe-loading-box {
    @include dis_style(flex,center,center);
    @include base_style(100%);
    position: absolute;
    background: #fff;
    .iframe-loading-img {
    @include base_style(48px);
      margin-top: -60px;
    }
  }
}
</style>

<style lang="scss">
.el-dialog-iframe {
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__headerbtn .el-dialog__close {
    font-size: 16px !important;
  }
}
</style>